<template>
  <div>
    <!-- 搜索表单 -->
    <div class="title margin-b30">Shipment Search</div>
    <el-form
      :inline="true"
      :model="searchForm"
      class="demo-form-inline"
      ref="ruleForm"
    >
      <el-form-item label="Time" prop="date">
        <el-date-picker
          v-model="searchForm.date"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="searchBtn">
        <el-button type="primary" @click="onSubmit">Search</el-button>
        <el-button type="default" @click="resetForm('ruleForm')"
          >Reset</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 统计总数 -->
    <div class="title">Shipment Total</div>
    <el-row :gutter="12" style="margin: 30px 0">
      <el-col :span="3">
        <el-card shadow="always" style="background: #1ebfaf">
          <div class="num">
            {{ number.type_9 == null ? '0' : number.type_9 }}
          </div>
          <div class="name">Receive</div>
        </el-card>
      </el-col>
      <el-col :span="3">
        <el-card shadow="always" style="background: #5ea9cf">
          <div class="num">
            {{ number.type_1 == null ? '0' : number.type_1 }}
          </div>
          <div class="name">Enter</div>
        </el-card>
      </el-col>
      <el-col :span="3">
        <el-card shadow="always" style="background: #9ad256">
          <div class="num">
            {{ number.type_2 == null ? '0' : number.type_2 }}
          </div>
          <div class="name">Out</div>
        </el-card>
      </el-col>
      <el-col :span="3">
        <el-card shadow="always" style="background: #b097db">
          <div class="num">
            {{ number.type_3 == null ? '0' : number.type_3 }}
          </div>
          <div class="name">Measure</div>
        </el-card>
      </el-col>
      <el-col :span="3">
        <el-card shadow="always" style="background: #f9df37">
          <div class="num">
            {{ number.type_4 == null ? '0' : number.type_4 }}
          </div>
          <div class="name">Put</div>
        </el-card>
      </el-col>
      <el-col :span="3">
        <el-card shadow="always" style="background: #ff6e72">
          <div class="num">
            {{ number.type_5 == null ? '0' : number.type_5 }}
          </div>
          <div class="name">Return</div>
        </el-card>
      </el-col>
      <el-col :span="3">
        <el-card shadow="always" style="background: #fa8e68">
          <div class="num">
            {{ number.type_6 == null ? '0' : number.type_6 }}
          </div>
          <div class="name">Lose</div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 统计图表 -->
    <div class="chartscont">
      <div id="main" style="width: 100%; height: 400px" ref="home"></div>
    </div>
  </div>
</template>

<script>
import Echarts from 'echarts'
import { statistic } from '@/api/user.js'
export default {
  data() {
    return {
      searchForm: {
        date: ''
      },
      number: {},
      list: {
        info: {},
        xaxis: []
      }
    }
  },
  mounted() {
    this.getmsg()
  },
  methods: {
    onSubmit() {
      this.getmsg()
    },
    // 重置
    resetForm() {
      this.searchForm.date = ''
      this.getmsg()
      this.$refs.ruleForm.resetFields()
    },
    async getmsg() {
      const data = await statistic({
        start_date: this.searchForm.date[0],
        end_date: this.searchForm.date[1]
      })
      // console.log(data)
      this.number = data.count
      this.list.info = data.day
      this.list.xaxis = data.day_keys
      this.echarsInfo()
    },
    echarsInfo() {
      const myChart = Echarts.init(this.$refs.home)
      const option = {
        title: {
          text: 'Shipment Chart'
        },
        tooltip: {
          trigger: 'axis'
        },
        color: [
          ' #1ebfaf',
          '#5ea9cf',
          '#9ad256',
          '#b097db',
          '#f9df37',
          '#ff6e72',
          '#fa8e68'
        ],
        legend: {
          data: ['Receive', 'Enter', 'OUT', 'Measure', 'Put', 'Return', 'Lose']
        },
        grid: {
          left: '7%',
          right: '7%',
          bottom: '12%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: false
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.list.xaxis
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [
          {
            type: 'inside',
            start: 100,
            end: 80
          },
          {
            start: 100,
            end: 80
          }
        ],
        series: [
          {
            name: 'Receive',
            type: 'line',
            stack: 'total',
            data: this.list.info.type_9
          },
          {
            name: 'Enter',
            type: 'line',
            stack: 'total',
            data: this.list.info.type_1
          },
          {
            name: 'OUT',
            type: 'line',
            stack: 'total',
            data: this.list.info.type_2
          },
          {
            name: 'Measure',
            type: 'line',
            stack: 'total',
            data: this.list.info.type_3
          },
          {
            name: 'Put',
            type: 'line',
            stack: 'total',
            data: this.list.info.type_4
          },
          {
            name: 'Return',
            type: 'line',
            stack: 'total',
            data: this.list.info.type_5
          },
          {
            name: 'Lose',
            type: 'line',
            stack: 'total',
            data: this.list.info.type_5
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.margin-b30 {
  margin-bottom: 30px;
}
.title {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.el-col {
  padding: 10px;
  text-align: center;
  .num {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    // text-align: justify;
    text-justify: newspaper;
    word-break: break-all;
  }
  .name {
    font-size: 16px;
    color: #fff;
  }
}
.el-date-editor--daterange.el-input__inner {
  width: 650px;
}
.el-col-3 {
  width: 14.28%;
}
</style>
